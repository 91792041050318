<template>
  <div class="mx-auto secondary--text">
    <v-btn
      color="info"
      icon
      class="float-right ma-8 btn--plain"
      @click="$emit('close:form')"
    >
      <v-icon>mdi-close</v-icon>
    </v-btn>
    <div style="width: 80%" class="pt-10 pb-4 mx-auto text-left">
      <div class="custom-title pt-8 pb-4">Past Due Rent</div>
      <div class="pb-8">
        Select all the periods in which the tenant has failed to pay rent, as
        agreed in the lease. If you need to make changes, you may edit the
        amount due.
      </div>

      <div style="height: 420px; overflow: auto">
        <vue-scroll :ops="ops">
          <v-data-table
            v-model="selected"
            :headers="headers"
            :items="rentsDue"
            item-key="timePeriod"
            show-select
            class="elevation-1"
            hide-default-footer
            disable-pagination
            disable-sort
          >
            <template v-slot:item.amount="props">
              <v-edit-dialog
                :return-value.sync="props.item.amount"
                large
                persistent
                @save=props.item
              >
                <div>
                  {{ convertToCurrency(props.item.amount) }}
                  <v-icon size="14" color="#4dccc4" class="ml-1">
                    $pencil
                  </v-icon>
                </div>
                <template v-slot:input>
                  <div class="mt-4">Change amount</div>
                  <v-text-field
                    v-model="props.item.amount"
                    label="Edit"
                    single-line
                    autofocus
                    type="number"
                  ></v-text-field>
                </template>
              </v-edit-dialog>
            </template>
          </v-data-table>
        </vue-scroll>
      </div>
      <h2 class="text-right">Total due: {{ convertToCurrency(rentTotal) }}</h2>
    </div>
    <v-card-actions
      style="background-color: #fafbfc; border-radius: 0px 0px 10px 10px"
      class="justify-end py-4 pr-12 mt-12"
    >
      <v-btn
        rounded
        color="accent_light"
        class="mb-2 mt-4 btn--plain capital--btn"
        text
        @click="$router.push({ name: 'vc-lease-details' })"
      >
        go back
      </v-btn>
      <v-btn
        rounded
        color="accent_light"
        class="px-8 mb-2 mt-4 white--text capital--btn"
        depressed
        @click="next"
      >
        continue
      </v-btn>
    </v-card-actions>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "PastDueRent",
  props: {
    resetDataFromLeaseDetails: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      rentsDue: [],
      headers: [
        {
          text: "Period",
          align: "left",
          value: "timePeriodDisplay",
        },
        {
          text: "Amount",
          align: "left",
          value: "amount",
        },
      ],
      selected: [],
      endLeaseCounter: this.case ? this.case.leaseStartDate : "",
      ops: {
        vuescroll: {},
        scrollPanel: {
          speed: 100,
          easing: "easeInQuad",
        },
        rail: {
          background: "#EAEBEB",
          opacity: 1,
          size: "10px",
          specifyBorderRadius: false,
          gutterOfEnds: "10px",
          gutterOfSide: "0px",
        },
        bar: {
          onlyShowBarOnScroll: false,
          keepShow: true,
          background: "#4DCCC4",
          opacity: 1,
          size: "10px",
          minSize: 0.1,
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      currentProperty: "property/currentProperty",
      case: "eviction/currentCase",
    }),
    valid() {
      return this.selected.length > 0;
    },
    lastPeriodEnd() {
      if (this.rentsDue.length === 0) {
        //first of all add the current month
        const today = this.$dayjs().format("YYYY-MM");
        return `${today}-${
          this.case.rentDueBy < 10
            ? `0${this.case.rentDueBy}`
            : this.case.rentDueBy
        }`;
      }
      //this is the last item in the rentsDue arrray
      const lastPeriod = this.rentsDue[this.rentsDue.length - 1].timePeriod;

      //this is getting it not to be an array ???
      if (Array.isArray(lastPeriod)) {
        return lastPeriod[1];
      }
      return lastPeriod;
    },
    rentAmount() {
      return this.case.rentAmount;
    },
    selectedRents() {
      return this.rentsDue.filter((r) => r.selected);
    },
    rentTotal() {
      return this.selected.reduce((acc, cur) => {
        return acc + parseFloat(this.convertToNumber(cur.amount));
      }, 0);
    },
  },
  async activated() {
    window.scrollTo(0, 0);
    this.rentsDue = this.case.pastDueRent;
    if (this.resetDataFromLeaseDetails) await this.generatePeriods();

    this.selected = this.rentsDue.filter((r) => r.selected);
  },
  deactivated() {
    this.saveData();
  },
  methods: {
    async generatePeriods() {
      this.rentsDue = [];
      this.endLeaseCounter = this.case.leaseStartDate || "";
      //let leasePeriodStart = "";

      const today = this.$dayjs().format("MM-D-YYYY");
      const todayDay = today.split("-")[1]; //day of the month

      //the below function removes the current month
      //if the rent is due after today's date
      if (typeof this.case.rentDueBy === "string") {
        if (todayDay < Number(this.case.rentDueBy)) {
          this.rentsDue.shift();
        }
      }

      //this is taking the year, taking the month of the lease
      //and adding a month to it
      //The month will always be the previous month (ie: for september, then dayjs().month will be 8)
      // const dateAry = [
      //   this.$dayjs(this.case.leaseStartDate).year(),
      //   this.$dayjs(this.case.leaseStartDate).month() + 1,
      //   Number(this.case.rentDueBy),
      // ];
      //leasePeriodStart = dateAry.join("-");

      //I only want it to add a previous month if the lease month is not in
      //once we add the lease month then we don't want any more months added

      //adding temp code for delinquent
      if (this.case.leaseStartDate === "2024-02-01") {
        this.addConsecutiveRentDue();
      }

      while (
        this.$dayjs(this.lastPeriodEnd).isAfter(this.case.leaseStartDate)
        // &&
        //  this.$dayjs(this.lastPeriodEnd).month() !=
        //    this.$dayjs(this.case.leaseStartDate).month())
      ) {
        this.addConsecutiveRentDue();
      }

      //the below function removes the  month of the lease if the
      //lease date started after the day the rent is due
      //as per Mr. G on Nov 6, sw commenting this out
      //because we want the month of the lease to come up as well
      // if (this.$dayjs(this.case.leaseStartDate).isAfter(leasePeriodStart)) {
      //   this.rentsDue.pop();
      // }

      this.saveCalculationData();
    },
    addConsecutiveRentDue() {
      const timePeriod = this.calculateConsecutiveTimePeriod();
      this.rentsDue.push({
        timePeriod,
        timePeriodDisplay: this.formatTimePeriod(timePeriod),
        amount: this.rentAmount,
        selected: false,
      });
    },
    calculateConsecutiveTimePeriod() {
      let today;

      if (this.rentsDue.length === 0) {
        today = this.$dayjs().format("YYYY-MM-DD");
        return (
          today.split("-")[0] +
          "-" +
          today.split("-")[1] +
          `-${parseInt(this.case.rentDueBy) > 9 ? "" : "0"}${
            this.case.rentDueBy
          }`
        );
      }
      return this.$dayjs(this.rentsDue[this.rentsDue.length - 1].timePeriod)
        .subtract(1, "months")
        .format("YYYY-MM-DD");
    },
    convertToNumber(amount) {
      if (amount && !isNaN(amount)) {
        return amount;
      } else {
        return 0;
      }
    },

    saveData() {
      // if (!this.valid) return;
      const selectedTimePeriods = this.selected.map((item) => item.timePeriod);
      const updatedRents = this.rentsDue.map((rent) => {
        return {
          ...rent,
          amount: this.convertToNumber(rent.amount),
          selected: selectedTimePeriods.includes(rent.timePeriod),
        };
      });
      this.$store.dispatch("eviction/updateCase", {
        caseData: {
          pastDueRent: updatedRents,
        },
        status: "vc-pastDueRent",
        logMessage: "PastDueRent.vue:635",
      });
    },
    saveCalculationData() {
      this.saveData();
    },
    next() {
      // if (!this.valid) return;
      this.$router.push({ name: "vc-additional-charges" });
    },
    formatTimePeriod(timePeriod) {
      return this.$dayjs(timePeriod).format("MMMM YYYY");
    },
  },
};
</script>

<style scoped>
.rent-item {
  height: 105px;
  padding: 24px;
  padding-left: 50px;
  margin-bottom: 10px;
  margin-right: 20px;
}

.__vuescroll >>> .__panel {
  overscroll-behavior: contain;
}
</style>
