<template>
  <div class="py-4 px-12 mx-4">
    <!-- <div v-if="showEvictionBanner" class="eviction-banner custom-banner mt-12">
      <v-icon class="accent--text close-icon" @click="closeEvictionBanner">
        mdi-close
      </v-icon>
      <i class="icofont-exclamation-circle icofont-2x accent--text" />
      <div class="text-part">
        <h4 class="accent--text banner-title">EVICTION MORATORIUM</h4>
        <p class="ma-0 secondary--text">
          As of January 1, 2022, all previous New Jersey state moratoriums
          enacted due to COVID-19 are lifted, and tenants may be evicted for any
          rent that becomes due and owing as of January 1, 2022. Tenants that
          have filed a Certification of Low Income may still be protected for
          rents that accrued between March 2020 and December 2021, under
          specific conditions.
          <a
            href="https://fileevictions.com/new-jersey-eviction-moratorium/"
            class="accent--text"
            target="_blank"
          >
            Read additional information
          </a>
        </p>
      </div>
    </div> -->

    <div class="d-flex align-center justify-space-between my-12">
      <div class="d-flex align-center">
        <div class="secondary--text custom-heading mr-12">Delinquents</div>
        <v-btn
          fab
          depressed
          color="primary"
          height="45"
          width="45"
          @click="startNewCase"
        >
          <span class="white--text font-weight-regular" style="font-size: 50px"
            >+</span
          >
        </v-btn>
      </div>
      <div class="d-flex align-center" style="cursor: not-allowed">
        <div class="info--text custom-heading mr-12">Evictions</div>
        <v-btn
          fab
          depressed
          disabled
          color="primary"
          height="45"
          width="45"
          @click="startNewCase"
        >
          <span class="white--text font-weight-regular" style="font-size: 50px"
            >+</span
          >
        </v-btn>
      </div>
      <div class="table-custom-search">
        <i class="icofont-search-1" />
        <input v-model="search" type="text" placeholder="Search Cases" />
      </div>
    </div>

    <div v-if="cases.length > 0" class="d-flex">
      <span class="secondary--text">Sort By:</span>
      <span class="mx-3 secondary--text font-weight-medium"
        >Recent Activity</span
      >
      <div class="d-flex info--text sort-actions">
        <span
          class="custom-sort-icon"
          :class="`${sortDirection === 'desc' ? 'active' : ''}`"
          @click="sortDescCases"
        >
          <arrow-up />
        </span>
        <span
          class="custom-sort-icon"
          :class="`${sortDirection === 'asc' ? 'active' : ''}`"
          @click="sortAscCases"
        >
          <arrow-down />
        </span>
      </div>
    </div>

    <div>
      <eviction-table :search="search" />
    </div>
  </div>
</template>

<script>
import EvictionTable from "@/components/tables/EvictionTable";
import ArrowUp from "@/components/icons/ArrowUp.vue";
import ArrowDown from "@/components/icons/ArrowDown.vue";
import { differenceInCalendarDays } from "date-fns";
import { mapGetters } from "vuex";

export default {
  name: "Main",
  components: {
    EvictionTable,
    ArrowUp,
    ArrowDown,
  },
  data() {
    return {
      showUpcomingBanner: false,
      showEvictionBanner: false,
      search: "",
      sortDirection: "asc",
      evictionTable: true,
      filterDialogOpen: false,
    };
  },
  computed: {
    ...mapGetters({
      cases: "eviction/cases",
    }),
  },
  async created() {
    if (!this.$auth.isAuthenticated) return this.$auth.loginWithRedirect();
    window.scrollTo(0, 0);

    await this.$store.dispatch("eviction/loadCases");

    this.showUpcomingBanner = !this.$store.getters.client.closedUpcomingBanner;
    const closedEvictionBannerTime =
      this.$store.getters.client.closedEvictionBannerTime;
    if (!closedEvictionBannerTime) this.showEvictionBanner = true;
    else {
      const diffInDays = differenceInCalendarDays(
        new Date(),
        new Date(closedEvictionBannerTime)
      );
      if (diffInDays >= 7) {
        this.showEvictionBanner = true;
      }
    }
  },
  methods: {
    startNewCase() {
      this.$router.push("verified-complaint/new");
      this.$store.commit("eviction/init_steps");
      this.$store.commit("eviction/init_case");
      this.$store.commit("property/init_property");
    },
    closeUpcomingBanner() {
      this.showUpcomingBanner = false;
      this.$store.dispatch("updateUser", { closedUpcomingBanner: 1 });
    },
    closeEvictionBanner() {
      this.showEvictionBanner = false;
      const today = new Date().toISOString().slice(0, 10);
      this.$store.dispatch("updateUser", { closedEvictionBannerTime: today });
    },
    sortDescCases() {
      this.sortDirection = "desc";
      this.$store.dispatch("eviction/sortCases", this.sortDirection);
    },
    sortAscCases() {
      this.sortDirection = "asc";
      this.$store.dispatch("eviction/sortCases", this.sortDirection);
    },
  },
};
</script>

<style>
.custom-banner {
  position: relative;
  padding: 20px 30px 30px;
  border-radius: 15px;
  display: flex;
  align-items: baseline;
}

.eviction-banner {
  background: #ff854e39 0% 0%;
  border: 6px solid #ff854e;
}

.custom-banner .close-icon {
  position: absolute;
  cursor: pointer;
}

.custom-banner .text-part {
  margin-left: 20px;
}

.banner-title {
  font-size: 30px;
  line-height: 26px;
  font-weight: 500;
  margin-bottom: 16px;
}

.banner-description {
  margin: 0;
}

.upcoming-banner {
  background: #6a7fdb38;
  border: 6px solid #6a7fdb;
}

/* Table Search section style */
.table-custom-search i {
  color: #8ba4b4;
  margin-right: 20px;
}

.table-custom-search input {
  outline: none;
  color: #8ba4b4;
  font-size: 18px;
}

.table-custom-search input::placeholder {
  color: #8ba4b4;
}

.sort-actions i {
  cursor: pointer;
  width: 10px;
}

.sort-actions .custom-sort-icon {
  color: #8ba4b4;
  cursor: pointer;
}

.sort-actions .custom-sort-icon.active {
  color: #4dccc4;
}
</style>
