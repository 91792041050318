<template>
  <v-card class="secondary--text rounded-card">
    <v-btn
      color="info"
      icon
      class="float-right ma-8 btn--plain"
      @click="$emit('close:form')"
    >
      <v-icon>mdi-close</v-icon>
    </v-btn>
    <div style="width: 80%" class="pb-4 mx-auto text-left">
      <div class="custom-title pt-8 pb-4">Download Documents</div>
      <div>
        <p>
          Click Download to view and save the full package of the Delinquent
          Letter to Tenant and a Draft Verified Complaint.
        </p>
      </div>
      <selection v-model="newFilingPlan" :btns="btns" @input="itemClicked" />
    </div>
    <v-card-actions
      style="background-color: #fafbfc"
      class="justify-end py-6 px-12"
    >
      <div class="mx-auto secondary--text" style="width: 85%">
        <v-container fluid class="container-order-details-redirect mt-5">
          <v-row align="center" justify="center" class="h-full">
            <v-col cols="6">
              <v-row
                style="font-size: 28px"
                class="font-weight-medium mt-10"
                align="center"
                justify="center"
              >
                <v-btn
                  rounded
                  dark
                  class="px-12 capital--btn mr-7 order-details-bottom-button"
                  depressed
                  outlined
                  color="primary"
                  style="border: 3px solid #4dccc4; padding: 20px !important"
                  @click="
                    $router.push({
                      name: isCLRSOnlyOrder ? 'saf-signature' : 'vc-signature',
                    })
                  "
                >
                  go back
                </v-btn>
                <v-btn
                  rounded
                  :color="'primary'"
                  dark
                  class="px-12 capital--btn order-details-bottom-button"
                  depressed
                  style="padding: 23px !important"
                  @click="$router.push({ name: 'main' })"
                >
                  dashboard
                </v-btn>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <v-dialog v-model="printPleadingsDialog" value="''" max-width="900">
        <PrintPleadingsDialog
          @close:dialog="printPleadingsDialog = false"
          :isDelinquent="true"
        />
      </v-dialog>
    </v-card-actions>
  </v-card>
</template>

<script>
import Selection from "@/components/Selection.vue";
import PrintPleadingsDialog from "@/components/dialogs/PrintPleadingsDialog";
import { mapGetters } from "vuex";

export default {
  name: "Filing",
  components: { Selection, PrintPleadingsDialog },
  props: {},
  data() {
    return {
      valid: false,
      newFilingPlan: "",
      btns: ["Download"],
      printPleadingsDialog: false,
    };
  },
  computed: {
    ...mapGetters({
      case: "eviction/currentCase",
    }),
  },

  activated() {
    window.scrollTo(0, 0);
    this.newFilingPlan = this.case.filingPlan || "";
  },
  methods: {
    itemClicked() {
      this.printPleadingsDialog = true;
    },
    next() {
      this.$router.push({
        name: "vc-signature",
        params: { filingPlan: this.newFilingPlan },
      });
    },
  },
};
</script>
<style></style>
