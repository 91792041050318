import { store } from "../store";
import updateCaseSteps from "./updateCaseSteps";

export default (data) => {
  const prevCase = store.getters["eviction/currentCase"];
  const tenants = data.tenants || [];
  
  const updatedCase = {
    id: data.id,
    actions: data.actions,
    clientId: data.clientId,
    clientInfo: data.clientInfo,
    docketNo: data.docketNo,
    manageConf: data.manageConf,
    manageConfType: data.manageConfType,
    hasOpposingCounsel:
      data.hasOpposingCounsel !== null
        ? Boolean(data.hasOpposingCounsel)
        : null,
    clientAttendCaseManage:
      data.clientAttendCaseManage !== null
        ? Boolean(data.clientAttendCaseManage)
        : null,
    clientAttendTrial:
      data.clientAttendTrial !== null ? Boolean(data.clientAttendTrial) : null,
    opposingCounselInfo: data.opposingCounselInfo,
    deedNames: data.deedNames,
    virtualRoom: data.virtualRoom,
    paymentPlan: data.paymentPlan ? JSON.parse(data.paymentPlan) : null,
    additionalConditions: data.additionalConditions
      ? JSON.parse(data.additionalConditions)
      : null,
    trialDate: data.trialDate,
    trialTime: data.trialTime,
    trialType: data.trialType,
    trialAction: data.trialAction,
    breachTerms: data.breachTerms ? JSON.parse(data.breachTerms) : [],
    breachPayments: data.breachPayments ? JSON.parse(data.breachPayments) : [],
    breachOtherConds: data.breachOtherConds
      ? JSON.parse(data.breachOtherConds)
      : [],
    courtOutcome: data.courtOutcome,
    courtInfoTime: data.courtInfoTime,
    vcDateFiled: data.vcDateFiled,
    vcDateConsentFiled: data.vcDateConsentFiled,
    vcDateDrafted: data.vcDateDrafted,
    vcDateConsentDrafted: data.vcDateConsentDrafted,
    status: data.status,
    statusDate: data.statusDate,
    propertyId: data.propertyId,
    tenantInfoCorrect: tenants.length > 0 ? "yes" : "",
    tenants: data.tenants || [],
    tenantsSaved: tenants.length > 0,
    tenantType: tenants[0]?.tenantType || "",
    tenantPhone: tenants[0]?.phone || "",
    tenantExt: tenants[0]?.ext || "",
    tenantPhoneType: tenants[0]?.phoneType || "",
    tenantCell: tenants[0]?.cell || "",
    tenantEmail: tenants[0]?.email || "",
    tenantContactName: tenants[0]?.contactName || "",
    leaseTypeTitle: data.leaseTypeTitle,
    leaseTypeCategory: data.leaseTypeCategory,
    leaseStartDate: data.leaseStartDate,
    signatureImage: data.signatureImage,
    signatureImageClrs: data.signatureImageClrs,
    signatureText: data.signatureText,
    section8: data.section8,
    section8Type: data.section8Type,
    section8Subsidy: data.section8Subsidy,
    section8TenantPortion: data.section8TenantPortion,
    publicHousingOfficeSection8: JSON.parse(data?.publicHousingOfficeSection8),
    confirmation: JSON.parse(data?.confirmation) || {},
    verification: JSON.parse(data?.verification) || {},
    rentDueBy:
      data.rentCycle === "Semi-monthly"
        ? data.rentDueBy.split(", ")
        : data.rentDueBy,
    rentAmount: data.rentAmount,
    rentCycle: data.rentCycle,
    consentTermRents: data.consentTermRents
      ? JSON.parse(data.consentTermRents)
      : [],
    consentTotalBaseRent: data.consentTotalBaseRent,
    consentAgreement: data.consentAgreement,
    consentAgreementText: data.consentAgreementText,
    pastDueRent: data.pastDueRent ? JSON.parse(data.pastDueRent) : [],
    pastDueRentPaid: data.pastDueRentPaid,
    pastDueRentPayments: data.pastDueRentPayments
      ? JSON.parse(data.pastDueRentPayments)
      : [],
    consentPayments: data.consentPayments
      ? JSON.parse(data.consentPayments)
      : [],
    additionalChargesPermitted: data.additionalChargesPermitted,
    lateCharges: data.lateCharges ? JSON.parse(data.lateCharges) : [],
    utilities: data.utilities ? JSON.parse(data.utilities) : [],
    fees: data.fees ? JSON.parse(data.fees) : [],
    otherCharges: data.otherCharges ? JSON.parse(data.otherCharges) : [],
    legalFeesPermitted: data.legalFeesPermitted,
    legalFees: data.legalFees ? JSON.parse(data.legalFees) : [],
    documentsUploadPermitted: data.documentsUploadPermitted,
    filingPlan: data.filingPlan,
    moveOutDate: data.moveOutDate,
    clrsDraft: data.clrsDraft !== null ? Boolean(data.clrsDraft) : null,
    leaseCopySubmission:
      data.leaseCopySubmission !== null
        ? Boolean(data.leaseCopySubmission)
        : null,
    uploadLease: data.uploadLease !== null ? Boolean(data.uploadLease) : null,
    documentDetailType: data.documentDetailType,
    clrsSubmission:
      data.clrsSubmission !== null ? Boolean(data.clrsSubmission) : null,
    clrsUploadRegistration:
      data.clrsUploadRegistration !== null
        ? Boolean(data.clrsUploadRegistration)
        : null,
    clrsExemption:
      data.clrsExemption !== null ? Boolean(data.clrsExemption) : null,
    clrsCompleted:
      data.clrsCompleted !== null ? Boolean(data.clrsCompleted) : null,
    isClrsInStandAloneForm: data.isClrsInStandAloneForm,
  };

  if (prevCase.documents?.length) {
    updatedCase.documents = prevCase.documents;
  }
  store.commit("eviction/set_current_case", updatedCase);

  let cases = store.getters["eviction/cases"];
  if (data.id) {
    cases = cases.filter((item) => item.id !== data.id);
    cases = [...cases, data];
    cases.sort((a, b) => (a.updated_at < b.updated_at ? 1 : -1));
    store.commit("eviction/set_cases", cases);
  }
  updateCaseSteps(updatedCase);
};
