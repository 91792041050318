import { render, staticRenderFns } from "./UtilitiesAndFeesDialog.vue?vue&type=template&id=65d5b414"
import script from "./UtilitiesAndFeesDialog.vue?vue&type=script&lang=js"
export * from "./UtilitiesAndFeesDialog.vue?vue&type=script&lang=js"
import style0 from "./UtilitiesAndFeesDialog.vue?vue&type=style&index=0&id=65d5b414&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports