<template>
  <v-list class="py-0 side-list">
    <div v-for="(item, index) in caseSteps" :key="index">
      <v-list-item
        v-if="item.status !== 'hidden'"
        class="py-1 d-flex justify-space-between"
        :class="[
          item.status === 'completed'
            ? 'side-list-complete'
            : 'side-list-incomplete',
          $route.name === item.routeName ||
          (item.routeName === 'wor-court-outcome' &&
            $route.meta === 'wor' &&
            !$route.name.includes('trial'))
            ? 'item-selected'
            : '',
        ]"
        :style="item.hide ? 'display: none !important;' : ''"
        @click="goToCasePage(item.routeName)"
      >
        <div :class="textColor(item)">
          <v-icon :color="iconColor(item)" class="pr-2">
            mdi-{{
              item.status === "completed"
                ? "check-circle"
                : "checkbox-blank-circle-outline"
            }}
          </v-icon>
          {{ item.label }}
        </div>
        <v-spacer />
        <v-btn
          v-if="hasChildren(item)"
          :color="iconColor(item)"
          class="btn--plain"
          icon
          @click.stop="showChild(item.routeName)"
        >
          <v-icon>mdi-chevron-{{ direction(item.showChildren) }}</v-icon>
        </v-btn>
      </v-list-item>
      <div
        v-if="item.label === 'Post Trial Actions'"
        class="secondary--text mb-2 text-center"
        style="font-size: 15px"
      >
        {{ currentCase.trialAction }}
      </div>
      <v-expand-transition>
        <div v-if="item.showChildren" class="side-list-subchild">
          <div v-for="child in item.children" :key="child.label">
            <v-list-item
              v-if="child.status !== 'hidden'"
              class="py-1 pl-6"
              :class="
                $route.name === child.routeName ? 'property-item-selected' : ''
              "
              @click="goToChild(child)"
              :style="child.hide ? 'display: none !important;' : ''"
            >
              <div class="secondary--text">
                <v-icon color="primary" class="pr-2">
                  mdi-{{
                    child.status === "completed"
                      ? "check-circle"
                      : "checkbox-blank-circle-outline"
                  }}
                </v-icon>
                {{ child.label }}
              </div>
              <v-spacer />
              <v-btn
                v-if="hasChildren(child)"
                color="primary"
                class="btn--plain"
                icon
                @click.stop="showChildProperties(child)"
              >
                <v-icon>mdi-chevron-{{ direction(child.showChildren) }}</v-icon>
              </v-btn>
            </v-list-item>
            <v-expand-transition>
              <div v-if="child.showChildren" class="side-list-subchild">
                <div
                  v-for="grandChild in child.children"
                  :key="grandChild.label"
                >
                  <v-list-item
                    v-if="grandChild.status !== 'hidden'"
                    class="py-1 pl-10"
                    :class="
                      $route.name === grandChild.routeName
                        ? 'property-item-selected'
                        : ''
                    "
                    @click="goToPropertyPage(grandChild)"
                  >
                    <div class="secondary--text">
                      <v-icon color="primary" class="pr-2">
                        mdi-{{
                          grandChild.status === "completed"
                            ? "check-circle"
                            : "checkbox-blank-circle-outline"
                        }}
                      </v-icon>
                      {{ grandChild.label }}
                    </div>
                  </v-list-item>
                </div>
              </div>
            </v-expand-transition>
          </div>
        </div>
      </v-expand-transition>
    </div>
  </v-list>
</template>

<script>
import { mapGetters } from "vuex";
import { trialActionsString } from "@/utils/worFlowData";

export default {
  name: "SideList",
  computed: {
    ...mapGetters({
      currentCase: "eviction/currentCase",
      property: "property/currentProperty",
      caseSteps: "eviction/caseSteps",
    }),
    direction() {
      return (name) => (name ? "up" : "down");
    },
  },
  created() {
    if (!this.currentCase?.actions) return;
    const actionId = this.currentCase.actions[0]?.actionId;
    if (actionId === 61) { // Request for Warrant Of Removal
      let requestWORCompleted = false;
      if (trialActionsString().slice(0, 5).includes(this.currentCase.trialAction)) {
        requestWORCompleted = true;
      }
      this.caseSteps.requestWOR = {
        label: "Request Warrant Of Removal",
        status: requestWORCompleted ? "completed" : "",
        routeName: "warrant-of-removal",
      };
    }
  },
  methods: {
    hasChildren(item) {
      return item.children;
    },
    textColor({ status, routeName }) {
      if (status === "completed" || routeName === this.$route.name)
        return "white--text";
      if (status === "disabled") return "info--text";
      return "secondary--text";
    },
    iconColor({ status, routeName }) {
      if (status === "completed" || routeName === this.$route.name)
        return "white";
      if (status === "disabled") return "info";
      return "primary";
    },
    goToCasePage(name) {
      if (name === "verified-complaint") {
        this.showChild(name);
        return;
      }
      if (name === "warrant-of-removal") {
        this.showChild(name);
        if (this.$route.name !== "wor-trial-action")
          this.$router.push({ name: "wor-trial-action" });
        return;
      }

      let routeName = name;
      if (
        name === "vc-additional-charges" &&
        this.currentCase.additionalChargesPermitted
      )
        routeName = "vc-additional-charges-extended";

      if (routeName === this.$route.name) return;
      const propParams = { id: this.currentCase.id || "new" };
      this.$router.push({
        name: routeName,
        params: propParams,
      });
    },
    goToChild(child) {
      if (child.routeName === "select-property") {
        this.showChildProperties();
        return;
      }

      let routeName = child.routeName;
      if (
        child.routeName === "vc-additional-charges" &&
        this.currentCase.additionalChargesPermitted
      )
        routeName = "vc-additional-charges-extended";

      if (routeName === this.$route.name) return;
      this.$router.push({
        name: child.routeName,
        params: { id: this.currentCase.id || "new" },
      });
    },
    goToPropertyPage(child) {
      if (child.routeName === this.$route.name) return;
      if (
        child.routeName &&
        (child.status === "completed" || this.isPropertyStepCompleted())
      ) {
        this.$router.push({
          name: child.routeName,
          params: { id: this.currentCase.id || "new" },
        });
      }
    },
    isPropertyStepCompleted() {
      return (
        this.caseSteps.verifiedComplaint.children.property.status ===
        "completed"
      );
    },
    showChild(routeName) {
      const { caseSteps } = this;
      if (routeName === "verified-complaint")
        caseSteps.verifiedComplaint.showChildren =
          !caseSteps.verifiedComplaint.showChildren;
      else
        caseSteps.trialOutcome.showChildren =
          !caseSteps.trialOutcome.showChildren;
      this.$store.commit("eviction/update_steps", caseSteps);
    },
    showChildProperties() {
      const { caseSteps } = this;
      caseSteps.verifiedComplaint.children.property.showChildren =
        !caseSteps.verifiedComplaint.children.property.showChildren;
      this.$store.commit("eviction/update_steps", caseSteps);
    },
  },
};
</script>

<style>
.side-list {
  border-radius: 10px;
  min-width: 300px;
}

.side-list > div:first-child > div {
  border-radius: 10px 10px 0 0;
}

.side-list > div:last-child > div {
  border-radius: 0 0 10px 10px;
}

.side-list-incomplete {
  background-color: white;
  pointer-events: none;
  opacity: 1;
}

.side-list-complete {
  background-color: #4dccc4;
}

.item-selected {
  background-color: rgba(77, 204, 196, 0.7);
}
.property-item-selected {
  background-color: #eff1f2;
}

.side-list-subchild {
  background-color: rgb(248, 250, 251);
  border-radius: 0% !important;
}
</style>
