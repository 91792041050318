import { render, staticRenderFns } from "./PropertyTable.vue?vue&type=template&id=51831088&scoped=true"
import script from "./PropertyTable.vue?vue&type=script&lang=js"
export * from "./PropertyTable.vue?vue&type=script&lang=js"
import style0 from "./PropertyTable.vue?vue&type=style&index=0&id=51831088&prod&lang=css"
import style1 from "./PropertyTable.vue?vue&type=style&index=1&id=51831088&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "51831088",
  null
  
)

export default component.exports