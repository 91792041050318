import Vue from "vue";
import Vuetify from "vuetify/lib";
import "@/styles.sass";
import Calendar from "@/components/icons/Calendar.vue";
import Pencil from "@/components/icons/Pencil.vue";
import Phone from "@/components/icons/Phone.vue";
import Cellphone from "@/components/icons/Cellphone.vue";
import Email from "@/components/icons/Email.vue";
import CircleCheckmark from "@/components/icons/CircleCheckmark.vue";
import ArrowUp from "@/components/icons/ArrowUp.vue";
import { ClickOutside } from "vuetify/lib/directives";

Vue.use(Vuetify, {
  directives: {
    ClickOutside,
  },
});

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#4DCCC4",
        secondary: "#003A60",
        secondary_light: "#53809D",
        accent: "#F86624",
        accent_light: "#FF854E",
        accent2: "#6A7FDB",
        info: "#AFC2CE",
        info_light: "#DAE3E9",
        extra: "#F8C1CB",
        alert: "#FA7274",
        blueish: "#45677d",
        blue_grey: "#93AEBF",
        background_grey: "#FAFBFC",
        grey_light: "#F0F5F6",
        slim: "#1E5273",
        white: "#fff",
        grey: "#8BA4B4",
      },
    },
  },
  icons: {
    values: {
      calendar: {
        // name of our custom icon
        component: Calendar,// our custom component
      },
      pencil: {
        component: Pencil,
      },
      phone: {
        component: Phone,
      },
      cellphone: {
        component: Cellphone,
      },
      email: {
        component: Email,
      },
      circleCheckmark: {
        component: CircleCheckmark,
      },
      arrowup: {
        component: ArrowUp,
      },
    },
  },
});
