import { render, staticRenderFns } from "./EditableField.vue?vue&type=template&id=2a948427"
import script from "./EditableField.vue?vue&type=script&lang=js"
export * from "./EditableField.vue?vue&type=script&lang=js"
import style0 from "./EditableField.vue?vue&type=style&index=0&id=2a948427&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports