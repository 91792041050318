<template>
    <div class="before-creating mx-auto secondary--text">
      <v-btn
        color="info"
        icon
        class="float-right ma-8 btn--plain"
        @click="$emit('close:form')"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
  
      <div style="width: 80%" class="pt-10 pb-4 mx-auto text-left">
        <div class="custom-title pt-8 pb-4">Delinquent Letter</div>
        <div class="pb-4">
          The Delinquent Letter contains a cover letter requesting the tenant to
          pay the outstanding rent, and appended to the Letter is a populated
          Verified Complaint. The purpose of the Delinquent Letter is to showcase
          to the tenant that the matter is serious and imminent.
          <br /><br />
          The following screens contain fields required for drafting the Verified
          Complaint. If the non-payment persists, return to FileEvictions and
          convert the case to an Evictions case. All the information previously
          inserted will be stored and used for the Eviction case.
        </div>
      </div>
  
      <v-card-actions
        style="background-color: #fafbfc; border-radius: 0px 0px 10px 10px"
        class="justify-end py-4 pr-12 mt-12"
      >
        <v-btn
          rounded
          color="accent_light"
          class="mb-2 mt-4 btn--plain capital--btn"
          text
          @click="cancel"
        >
          cancel
        </v-btn>
        <v-btn
          rounded
          color="accent_light"
          class="px-8 mb-2 mt-4 white--text capital--btn"
          depressed
          @click="next"
        >
          continue
        </v-btn>
      </v-card-actions>
    </div>
  </template>
  
  <script>
  export default {
    name: "DelinquentLetter",
    mounted() {
      window.scrollTo(0, 0);
    },
    methods: {
      next() {
        this.$router.push({ name: "vc-intro" });
      },
      cancel() {
        this.$router.push("/");
      },
    },
  };
  </script>