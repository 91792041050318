<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="9"
    height="16.842"
    viewBox="0 0 9 16.842"
  >
    <defs />
    <path
      class="custom-sort-svg-icon"
      d="M345.239,200.727v11.565h3.074c.4,0,.5.23.213.514l-3.823,3.823a.727.727,0,0,1-1.027,0l-3.823-3.823c-.283-.283-.188-.514.213-.514h3.077V200.727a.727.727,0,0,1,.727-.727h.643A.725.725,0,0,1,345.239,200.727Z"
      transform="translate(-339.689 -200)"
    />
  </svg>
</template>
<script>
export default {};
</script>

<style>
.custom-sort-svg-icon {
  fill: currentColor;
}
</style>
