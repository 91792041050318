import Vue from "vue";
import VueRouter from "vue-router";
import ChangePassword from "@/components/views/ChangePassword.vue";
import Unauthorized from "@/components/views/Unauthorized.vue";
import Main from "@/components/views/Main.vue";
import VerifiedComplaint from "@/components/views/VerifiedComplaint";
import Properties from "@/components/views/Properties";
import NewPropertyForm from "@/components/views/NewPropertyForm.vue";
import Type from "@/components/subpages/newProperty/Type";
import Address from "@/components/subpages/newProperty/Address";
import Owner from "@/components/subpages/newProperty/Owner";
import AssociationToOwner from "@/components/subpages/newProperty/AssociationToOwner";
import Contact from "@/components/subpages/newProperty/Contact";
import DelinquentLetter from "@/components/subpages/verifiedComplaint/DelinquentLetter";
import Intro from "@/components/subpages/verifiedComplaint/Intro";
import SelectProperty from "@/components/subpages/verifiedComplaint/SelectProperty";
import Tenants from "@/components/subpages/verifiedComplaint/Tenants";
import LeaseDetails from "@/components/subpages/verifiedComplaint/LeaseDetails";
import PastDueRent from "@/components/subpages/verifiedComplaint/PastDueRent";
import AdditionalCharges from "@/components/subpages/verifiedComplaint/AdditionalCharges";
import AdditionalChargesExtended from "@/components/subpages/verifiedComplaint/AdditionalChargesExtended";
import SuitAmountSummary from "@/components/subpages/verifiedComplaint/SuitAmountSummary";
import LegalFees from "@/components/subpages/verifiedComplaint/LegalFees";
import Filing from "@/components/subpages/verifiedComplaint/Filing";
import Signature from "@/components/subpages/verifiedComplaint/Signature";
import Profile from "@/components/views/Profile";
import AuthLoading from "@/components/views/AuthLoading";
import Terms from "@/components/views/Terms";

import { store } from "./store";

Vue.use(VueRouter);

const routes = [
  // AUTHORIZATION ROUTES
  {
    path: "/login",
    name: "login",
    component: AuthLoading, 
    meta: {
      isAuthRoute: true,
    },
  },
  {
    path: "/unauthorized",
    name: "unauthorized",
    component: Unauthorized,
    meta: {
      isAuthRoute: true,
    },
  },
  {
    path: "/change-password",
    name: "change-password",
    component: ChangePassword,
    meta: {
      isAuthRoute: true,
    },
  },
  {
    path: "/terms",
    name: "terms",
    component: Terms,
    meta: {
      title: "FileEvictions | Terms",
    },
  },
  {
    path: "/",
    name: "main",
    component: Main,
    meta: {
      title: "FileEvictions | Dashboard",
    },
  },
  {
    path: "/properties",
    name: "properties",
    component: Properties,
    meta: {
      title: "FileEvictions | Properties",
    },
  },
  {
    path: "/verified-complaint/:id",
    name: "verified-complaint",
    component: VerifiedComplaint,
    children: [
      {
        path: "before-creating",
        alias: "/",
        name: "vc-before-creating",
        component: DelinquentLetter,
      },
      {
        path: "intro",
        name: "vc-intro",
        component: Intro,
      },
      {
        path: "select-property",
        name: "vc-select-property",
        component: SelectProperty,
      },
      {
        path: "property",
        name: "vc-new-property",
        component: NewPropertyForm,
        children: [
          {
            path: "type",
            alias: "/",
            name: "vc-property-type",
            component: Type,
          },
          {
            path: "address",
            name: "vc-property-address",
            component: Address,
          },
          {
            path: "owner",
            name: "vc-property-owner",
            component: Owner,
          },
          {
            path: "owner-association",
            name: "vc-property-association-to-owner",
            component: AssociationToOwner,
          },
          {
            path: "contact",
            name: "vc-client-contact",
            component: Contact,
          },
        ],
      },
      {
        path: "tenants",
        name: "vc-tenants",
        component: Tenants,
      },
      {
        path: "lease-details",
        name: "vc-lease-details",
        component: LeaseDetails,
      },
      {
        path: "past-due-rent",
        name: "vc-past-due-rent",
        component: PastDueRent,
        props: true,
      },
      {
        path: "additional-charges",
        name: "vc-additional-charges",
        component: AdditionalCharges,
      },
      {
        path: "additional-charges-extended",
        name: "vc-additional-charges-extended",
        component: AdditionalChargesExtended,
      },
      {
        path: "suit-summary",
        name: "vc-suit-summary",
        component: SuitAmountSummary,
      },
      {
        path: "legal-fees",
        name: "vc-legal-fees",
        component: LegalFees,
      },
      {
        path: "filing",
        name: "vc-filing",
        component: Filing,
      },
      {
        path: "signature",
        name: "vc-signature",
        component: Signature,
        props: true,
      }
    ],
  },
  {
    path: "/profile",
    name: "profile",
    component: Profile,
    meta: {
      title: "FileEvictions | Profile",
    },
  },
  {
    path: "/as-user/:auth0Id",
    name: "as-user",
    component: Main,
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

router.beforeEach((to, from, next) => {
  store.commit("global/set_global_error", null, { root: true });
  if (!window.navigator.onLine) {
    store.commit(
      "global/set_global_error",
      { message: "You seem to be offline.", type: "offline" },
      {
        root: true,
      }
    );
  }
  next();
});

//* Set the page title
const DEFAULT_TITLE = "FileEvictions";
router.afterEach((to, from) => {
  Vue.nextTick(() => {
    document.title = to.meta.title || DEFAULT_TITLE;
  });
});

export default router;
