<template>
  <VerifiedComplaintBox
    @vcBoxContinue="next"
    vcContinueBtnText="Submit"
    @vcBoxCancel="goBack"
  >
    <div class="custom-title pt-8 pb-4">Let's Finalize</div>
    <div class="custom-subtitle mb-4 mt-2">Preview</div>
    <div class="mb-12">
      Please take a moment to review carefully. Should you need to make changes,
      jump to the section by clicking on the navigation menu in the sidebar.
    </div>
    <div
      style="
        height: 538px;
        border: 1px solid #8ba4b4;
        border-radius: 20px;
        position: relative;
        z-index: 1;
      "
      class="py-4"
    >
      <div class="magnify-icon">
        <v-icon large @click="openPDF"> mdi-magnify </v-icon>
      </div>
      <PdfPreviewProgressBar :isPdfLoading="isLoading" />
      <vue-scroll :ops="ops">
        <PdfViewer v-if="pdf" :src="pdf" />
      </vue-scroll>
    </div>

    <div class="custom-subtitle mb-4 mt-12">Signature Block</div>
    <div class="pb-6">
      Based on the information you have provided, your signature block will
      appear as follows. You may edit the information as you see fit.
    </div>
    <div
      style="height: 132px; padding: 20px"
      class="signature-pad mt-6 d-flex align-center justify-center"
    >
      <EditableField
        v-model="signatureText"
        inputStyles="width: 490px;"
        valueStyles="width: 490px;"
      />
    </div>

    <div v-if="isEfile">
      <div class="custom-subtitle mb-4 mt-12">Signature</div>
      <div class="pb-6">
        <div>
          All pleadings must be properly executed. Provide an electronic
          signature below. (Keep in mind, if you intend to mail in or file in
          person, please print the document and provide your original
          signature.)
        </div>
      </div>
      <VueSignaturePad
        ref="signaturePad"
        height="155px"
        class="signature-pad mt-6"
      />
      <v-btn
        style="margin-top: -40px"
        text
        class="btn--plain float-right pr-2"
        color="#8BA4B4"
        @click="clear"
      >
        Clear
      </v-btn>
    </div>
  </VerifiedComplaintBox>
</template>

<script>
import EditableField from "@/components/EditableField.vue";
import PdfViewer from "@/components/PdfViewer.vue";
import { getSignatureText, openPDFInNewTab } from "@/utils";
import { mapGetters } from "vuex";
import useRequest from "@/utils/useRequest";
import { VueSignaturePad } from "vue-signature-pad";
import VerifiedComplaintBox from "@components/VerifiedComplaintBox";
import dayjs from "dayjs";
import PdfPreviewProgressBar from "../../PdfPreviewProgressBar.vue";

export default {
  name: "Signature",
  components: {
    VerifiedComplaintBox,
    EditableField,
    PdfViewer,
    VueSignaturePad,
    PdfPreviewProgressBar,
  },
  data() {
    return {
      pdf: "",
      isLoading: false,
      isDataSaved: false,
      signatureText: "",
      ops: {
        vuescroll: {},
        scrollPanel: {},
        rail: {
          opacity: 0,
          size: "8px",
          specifyBorderRadius: false,
          gutterOfEnds: "10px",
          gutterOfSide: "0px",
        },
        bar: {
          onlyShowBarOnScroll: false,
          keepShow: true,
          background: "#4DCCC4",
          opacity: 1,
          size: "8px",
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      case: "eviction/currentCase",
      property: "property/currentProperty",
    }),
    isEfile() {
      return false;
    },
  },
  async activated() {
    window.scrollTo(0, 0);
    this.isDataSaved = false;
    this.pdf = null;
    this.isLoading = true;

    //* debounce after two seconds
    setTimeout(() => {
      if (this.$route.name === "vc-signature") this.renderTemplate();
      // console.error("don't forget...");
    }, 2000);

    this.signatureText =
      this.case.signatureText || getSignatureText(this.property);
  },

  deactivated() {
    !this.isDataSaved && this.saveData();
  },
  methods: {
    clear() {
      this.$refs.signaturePad.clearSignature();
      this.$refs.signaturePad.openSignaturePad();
    },

    async saveData(isCompletingCase = false) {
      this.isDataSaved = true;

      const caseData = {
        signatureText: "",
        isClrsInStandAloneForm: false,
      };

      if (this.signatureText !== getSignatureText(this.property)) {
        caseData.signatureText = this.signatureText;
      }

      if (this.isEfile) {
        //* Save new written signature
        const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
        if (!isEmpty) {
          caseData.newSignatureBase64 = data;
        }
      }
      caseData.vcDateDrafted = dayjs().format("YYYY-MM-DD");

      await this.$store.dispatch("eviction/updateCase", {
        caseData,
        status: isCompletingCase ? "verifiedComplaintComplete" : "vc-sign",
      });
    },
    next() {
      this.completeCase();
    },
    goBack() {
      this.$router.push({
        name: "vc-suit-summary",
      });
    },
    openPDF() {
      if (!this.pdf) return;
      const data64 = this.pdf.replace("data:application/pdf;base64,", "");
      if (data64 === "undefined") return;
      openPDFInNewTab("FileEviction | Preview", data64);
    },

    async completeCase(authType) {
      await this.saveData(true);
      const orderInfo = {
        caseId: this.case.id,
        payment_status: "Pending",
        packageId: 1,
        flowType: "01",
      };
      this.$store
        .dispatch("eviction/createOrder", {
          payload: { orderInfo },
        })
        .then(() => {
          this.$router.push({
            name: "vc-filing",
            query: { timestamp: Date.now() },
          });
        });
    },
    async renderTemplate() {
      console.log("renderTemplate * is executed, Signature.pdf:377");
      try {
        const guestData = false;
        let templateName;
        //temp hack to set up eviction for this specific case
        if (this.case.id === 1544 || this.case.id === 4594) {
          templateName = "eviction_preview";
        } else {
          templateName = "verified_complaint_preview";
        }
        const { data } = await useRequest({
          path: "create-case-pleading-pdf",
          method: "post",
          data: {
            caseId: this.case.id,
            guestData,
            templateName,
            isCLRSOnly: this.isInStandaloneForms,
          },
          throwError: true,
        });
        const linkSource = `data:application/pdf;base64,${data.fileData}`;
        this.pdf = linkSource;
      } catch (error) {
        console.log("renderTemplate error:", error);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style>
.signature-pad {
  background: #fafbfc 0% 0% no-repeat padding-box;
  border: 1px solid #8ba4b4;
  border-radius: 20px;
}
.magnify-icon {
  position: absolute;
  z-index: 10;
  top: 15px;
  right: 25px;
  cursor: pointer;
}
</style>
