<template>
  <StandardDialog
    title="Download"
    cancelBtnText="Cancel"
    saveBtnText="Done"
    :cancelFunction="close"
    :saveFunction="close"
    :formValid="true"
    contentWidth="75%"
  >
    <div class="pleadings-dialog">
      <div v-if="isWORFlow" class="pb-6">
        Upload the
        <span class="font-weight-bold">Certification by Landlord</span>,
        <span class="font-weight-bold">Cover Letter</span> and the
        <span class="font-weight-bold">Request for Warrant of Removal</span>
        to JEDS. Do NOT upload the instructions - they are for you.
      </div>
      <div v-if="isDelinquent"></div>
      <div v-else>
        <div v-if="currentCase.filingPlan === 'E-FILE'" class="pb-6">
          When submitting your documents through the Court's e-filing
          system—Judiciary Electronic Document Submission (JEDS)—you'll be asked
          to upload the
          <span class="font-weight-bold">Verified Complaint</span>,
          <span class="font-weight-bold">Tenant Summons</span> and
          <span class="font-weight-bold"
            >Landlord case information statement</span
          >
          separately. It is recommended to upload the
          <span class="font-weight-bold">Cover Letter</span> as a supporting
          document. Do NOT upload the instructions. Those are exclusively for you.
          Download the files you need.
        </div>
        <div v-else class="pb-6">
          Print all documents. Provide the court with the Cover Letter along with
          the
          <span class="font-weight-bold">Verified Complaint</span>,
          <span class="font-weight-bold">Tenant Summons</span> and
          <span class="font-weight-bold">Landlord case information statement</span
          >. Do NOT submit the instructions. Those are exclusively for you.
        </div>
      </div>

      <div
        v-for="(fileType, index) in fileList"
        :key="index"
        :class="
          fileType.icon_class === 'grey--text'
            ? 'letter-item-border-greyed-out'
            : 'letter-item-border'
        "
        :style="fileType.hide ? 'display: none' : ''"
        class="letter-item"
        style="background: #fafbfc"
        @click="printPleading(fileType)"
      >
        <v-row style="flex-wrap: nowrap">
          <v-col cols="10" style="display: flex; align-items: center">
            <i
              :class="[fileType.icon_class, fileType.icon]"
              class="icofont-2x mr-3"
            />
            <span
              :class="fileType.icon_class"
              style="position: relative; display: inline-block"
            >
              {{ fileType.name }}
            </span>
          </v-col>

          <v-col>
            <i
              :class="fileType.icon_class"
              class="icofont-download icofont-2x mr-5"
            ></i>
          </v-col>
        </v-row>
      </div>
    </div>
  </StandardDialog>
</template>

<script>
import { mapGetters } from "vuex";
import getPleadingMixin from "@/mixins/get-pleading";
import StandardDialog from "./StandardDialog.vue";

export default {
  data() {
    return {
      fileList: [
        {
          icon: "icofont-file-pdf",
          icon_class: "accent2--text",
          name: "Instructions",
          templateName: "instructions",
          hide: false,
        },
        {
          icon: "icofont-file-pdf",
          icon_class: "accent2--text",
          name: "Delinquent Letter",
          templateName: "delinquent_letter",
          hide: false,
        },
        {
          icon: "icofont-file-pdf",
          icon_class: "accent2--text",
          name: "Verified Complaint",
          templateName: "verified_complaint",
          hide: false,
        },
        {
          icon: "icofont-file-pdf",
          icon_class: "accent2--text",
          name: "Tenant Summons",
          templateName: "tenant_summons",
          hide: false,
        },
        {
          icon: "icofont-file-pdf",
          icon_class: "accent2--text",
          name: "Certification of Lease and Registration Statement",
          templateName: "clrs_form",
          hide: true,
        },
        {
          icon: "icofont-file-pdf",
          icon_class: "grey--text",
          name: "Print all (individual)",
          templateName: "print_all_individual",
          hide: false,
        },
        {
          icon: "icofont-file-pdf",
          icon_class: "accent2--text",
          name: "Download all",
          templateName: "print_all",
          hide: false,
        },
        {
          icon: "icofont-file-pdf",
          icon_class: "accent2--text",
          name: "Certification by Landlord",
          templateName: "certification_by_landlord",
          hide: true,
        },
        {
          icon: "icofont-file-pdf",
          icon_class: "accent2--text",
          name: "Request for Warrant of Removal",
          templateName: "wor_request",
          hide: true,
        },
      ],
    };
  },
  name: "PrintPleadingsDialog",
  mixins: [getPleadingMixin],
  components: { StandardDialog },
  props: {
    isCLRSInFlow: Boolean,
    isWORFlow: Boolean,
    isDelinquent: Boolean,
  },
  computed: {
    ...mapGetters({
      currentCase: "eviction/currentCase",
    }),
  },
  mounted() {
    if (this.isDelinquent) {
      this.fileList[0].hide = true;
      this.fileList[5].hide = true;
    }
    if (this.isCLRSInFlow) this.fileList[5].hide = false;
    if (this.isWORFlow) {
      for (let i = 0; i < 10; i++) {
        if (i == 0 || i == 8 || i == 9) this.fileList[i].hide = false;
        else this.fileList[i].hide = true;
      }
    }
  },
  methods: {
    close() {
      this.$emit("close:dialog");
    },
    async printPleading(fileType) {
      await this.getPleading({ templateName: fileType.templateName });
    },
  },
};
</script>

<style lang="scss">
.pleadings-dialog {
  margin-bottom: 70px;
  &__form {
    &-add-action {
      margin-top: -20px;
    }

    &-divider {
      margin: 0 auto;
    }
  }
}
.letter-item {
  padding: 5px 40px 5px 40px;
  margin: 20px 0 20px 0;
  border-radius: 9px !important;
  cursor: pointer;
}

.letter-item-border {
  border: 3px solid #6a7fdb !important;
}

.letter-item-border-greyed-out {
  padding: 5px 40px 5px 40px;
  margin: 20px 0 20px 0;
  border: 3px solid #9e9e9e !important;
  border-radius: 9px !important;
  cursor: pointer;
}
</style>
